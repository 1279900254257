import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { gallery, galleryItem } from "../styles/gallery.module.scss"
import MaybeLink from './MaybeLink'

export default function Gallery(props) {

    const { galleryData, ...rest } = props;

    if (!galleryData) return null 

    return (
        <div {...rest}>
            <div className={gallery}>

                {galleryData.map(gal => {

                    const itemData = getImage(gal.node.featuredImage?.node?.localFile)

                    const hasUrl = gal.node.presentsGallerySettings?.eventMediaUrl

                    let maybeUrl = null;

                    if (hasUrl) {
                        maybeUrl = hasUrl;
                    }

                    return (
                        <div className={galleryItem} key={gal.node.id}>
                            <MaybeLink link={maybeUrl} className={`cursor-pointer`} target={`_blank`} rel={`noopener noreferrer`} title={gal.node.title}>
                                <div>
                                    <GatsbyImage 
                                        image={itemData}
                                        layout={`fullWidth`}
                                        className={`shadow-md`}
                                        loading={`eager`}
                                        alt={gal.node.featuredImage.node.altText || gal.node.title}
                                    />
                                    </div>
                            </MaybeLink>
                        </div>
                    )
                })}

            </div>
        </div>
    )
}