import * as React from 'react'
import { graphql } from "gatsby"
// import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from "../components/Layout"
import PageWrapper from '../components/PageWrapper'
// import Seo from "../components/Seo"
import Seo2 from '../components/Seo2'
import { PageTitle } from '../components/PageTitles'
import { IconLogoFilled } from "../svg/LtaLogos"
import { logo, logoBounce } from "../styles/header.module.scss"
import Button from "../components/Button"
import Pagination from '../components/Pagination'
import Gallery from '../components/Gallery'
// import MasonryGallery from '../components/MasonryGallery'
// import MaybeLink from '../components/MaybeLink'


const PresentsArchive = ({ data, pageContext }) => {

    const topPosition = {
        top: 'calc(50% - 9rem/1.5)'
    }

    const { nextEventLink } = data?.nextEvent?.nextEventSettings

    const [eventLink, setEventLink] = React.useState(nextEventLink)

    React.useEffect(() => {
        async function loadSnippet() {
            const response = await fetch(`https://media.lighterair.com/wp-json/wp/v2/snippet/1458`);

            if(!response.ok) {
                // oups! something went wrong
                return;
            }

            const eventLink = await response.json()
            setEventLink(eventLink.acf.next_event_link)
        }

        loadSnippet()
    }, [])

    // console.log('Link: ' + eventLink.acf.next_event_link)


    return (
        <Layout>
             {/* <Seo title={`Presents`} description={`Upcoming events brought to you by Lighter Than Air.`}  /> */}

                <PageWrapper contained={true}>

                <PageTitle className={`hidden`}>LTA Presents</PageTitle>

                {/* className to account for top margin with hidden page title */}
                <Gallery galleryData={data.allWpGallery.edges} className={`!mt-0 xl:!-mt-10 xl:!-mb-10`} />
    

                <Pagination context={pageContext} />

                <div style={topPosition} className={`fixed bottom-auto right-0 left-0 m-auto z-[100] w-40 h-40 md:h-52 md:w-52 xl:w-56 xl:h-56 xl:-translate-y-12 flex flex-col justify-center items-center`}>

                    <IconLogoFilled 
                    className={`w-36 md:w-44 xl:w-48 mb-3 xl:mb-4 ${[logo, logoBounce].join(" ")}`}
                    primaryFill={`var(--text-contrast)`}
                    secondaryFill={`var(--color-primary)`} 
                    />
                    <Button className={`text-sm`} style={{padding: '0.65rem 0.75rem', color: 'var(--text-color)'}} type={`external`} href={eventLink ? eventLink : `https://lighterthanair.bandcamp.com/`} title={`Next Event`}>Next Event</Button>
                    {/* <Button className={`text-sm`} style={{padding: '0.65rem 0.75rem', color: 'var(--text-color)'}} type={`external`} href={nextEventLink ? nextEventLink : `https://lighterthanair.bandcamp.com/`} title={`Next Event`}>Next Event</Button> */}
                </div>

                </PageWrapper>
        </Layout>
    )
}

export default PresentsArchive

export const Head = () => <Seo2 title={`Presents`} description={`Upcoming events brought to you by Lighter Than Air.`} />

// export async function getServerData() {
//     const res = await fetch(`https://media.lighterair.com/wp-json/wp/v2/snippet/1458`)
//     const data = await res.json()
  
//     return {
//       props: {
//         realTimeLink: data.acf.next_event_link,
//       },
//       headers: {
//         'Content-Type': 'application/json;charset=utf-8'
//       }
//     }
// }

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allWpGallery(
      skip: $skip,
      limit: $limit
      sort: {
        fields: date
        order: DESC
      }
      filter: { status: { eq: "publish" }, featuredImageDatabaseId: {ne: null}}
    ) {
    edges {
        node {
            id
            slug
            title 
            featuredImage {
                node {
                    altText
                    localFile {
                    childImageSharp {
                        gatsbyImageData
                    }
                    }
                }
                }
            presentsGallerySettings {
                eventMediaUrl
            }
        }
    }
  }
  nextEvent: wpSnippet(id: {eq: "cG9zdDoxNDU4"}) {
    id
    title
    nextEventSettings {
        nextEventLink
    }
  }

}
`;