import React from 'react'
import { Link } from 'gatsby';
import { HiOutlineArrowRight, HiOutlineArrowLeft } from 'react-icons/hi';

export default function Pagination(props) {

    const { context, nextTitle, prevTitle, className } = props;

    if (!context.nextPagePath && !context.previousPagePath) return null

    return (
        <div className={className ? className : null}>

                {(context.nextPagePath || context.previousPagePath) &&

                <div className={`flex flex-row items-center justify-between text-color-text text-xxs md:text-xs uppercase font-mono`}>
                    <div className={`text-left`}>
                    {context.previousPagePath ? (
                        <Link className={`inline-flex flex-row justify-start items-center`} to={context.previousPagePath}><HiOutlineArrowLeft className={`mr-2 md:-mt-0.5 md:mr-3`} /> {prevTitle}</Link>
                    ) : (
                        <span className={`invisible pr-[12px]`}>{prevTitle}</span>
                    )
                        
                    }
                    </div>
                    {context.numberOfPages ? (
                        <div className={`md:block hidden px-10 xl:px-20`}>
                            { 
                                [context.humanPageNumber, '/', context.numberOfPages].join(" ")
                            }
                        </div>
                    ) : ( null )}
                    <div className={`text-right`}>
                    {context.nextPagePath ? (
                        <Link className={`inline-flex flex-row justify-end items-center`} to={context.nextPagePath}>{nextTitle} <HiOutlineArrowRight className={`ml-2 md:-mt-0.5 md:ml-3`} /></Link>
                    ) : (
                        <span className={`invisible pl-[12px]`}>{nextTitle}</span>
                    )
                        
                    }
                    </div>
                    
                    
                
                </div>
                }

                
        </div>

    )
}

Pagination.defaultProps = {
    nextTitle: `Later`,
    prevTitle: `Earlier`
}